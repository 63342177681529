import { withTheme } from '@emotion/react'
import React from 'react'
import GlobalCss from 'gatsby-theme-tmobile/components/CustomStyles/GlobalCss.styles'
import PackageCardCss from 'gatsby-theme-tmobile/components/CustomStyles/PackageCard.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <PackageCardCss />
  </>
))

export default GlobalStyles

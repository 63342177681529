module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.yourhomeinternet.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-5ZMZL66F","brandTokens":["TMO"],"mapiBrandToken":"TMO","siteName":"yourhomeinternet","alternateName":"yourhomeinternet.com","siteURL":"https://www.yourhomeinternet.com","defaultTitleTemplate":"","defaultPhone":"8335133933","phoneSymbol":"-","defaultPromoCode":"171637","smartyStreetsWebsiteKey":"","addressQualificationCode":"","datasetManagerAPIKey":"4d103f518e174ff08b22f6c9dbcab1d0","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yourhomeinternet","short_name":"yourhomeinternet","start_url":"/","background_color":"#E20074","theme_color":"#E20074","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2eea1a4bf2d42b9ab02f1802c92d0b43"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      //TODO: Styles added here as they were not working in theme.js (especially in the case of "stroke" and "label: {inside: true}")

      .leshen-package-card {
        .package-label {
          position: relative !important;
          top: 0 !important;
          left: 0 !important;
          margin: unset !important;
          border-radius: 28px !important;
        }

        .wrapper {
          border: 1px solid #6a6a6a !important;
        }

        .leshen-price-currency,
        .leshen-price-duration {
          font-size: 20px !important;
        }

        .leshen-price-currency {
          position: relative !important;
          top: -8px !important;
        }

        .leshen-price-duration {
          position: relative !important;
          bottom: 6px !important;
        }
      }
    `}
  />
))

export default GlobalCss

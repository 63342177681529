import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      //TODO: This radius should be added in the core, and pulled from the radius defined in the theme.
      // At this time core only allows for 'min' radius on buttons (4px) or 'max' (2em)

      button {
        border-radius: 8px !important;
      }

      .background-color-backgroundcolor1 {
        background-color: #545454 !important;
      }

      .background-color-backgroundcolor2,
      .background-color-backgroundcolor3,
      .background-color-backgroundcolor4,
      .background-color-backgroundcolor5,
      .background-color-backgroundcolor6 {
        background-color: #e20074 !important;
      }
    `}
  />
))

export default GlobalCss

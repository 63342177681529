/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#E20074',
    secondary: '#861B54',
    tertiary: '#C00063',
    dark: '#000000',
    light: '#f7f7f8',
    warning: '#ff7928',
    error: '#ff3068',
    success: '#2de6bc',
    backgroundColor1: '#545454',
    backgroundColor2: '#E20074',
    backgroundColor3: '#E20074',
    backgroundColor4: '#E20074',
    backgroundColor5: '#E20074',
    backgroundColor6: '#E20074',
  },
  alternateBgColor: true,
  backgroundColors: {
    primary: {
      textColor: '#f7f7f8',
    },
    dark: {
      textColor: '#f7f7f8',
    },
    backgroundColor1: {
      textColor: '#f7f7f8',
    },
  },
  fonts: {
    headings: {
      family: 'TeleNeoWeb Bold, Golos Text SemiBold',
    },
    subheadings: {
      family: 'TeleNeoWeb Bold, Golos Text SemiBold',
    },
    body: {
      family: 'TeleNeoWeb Regular, Golos Text Regular',
    },
  },
  components: {
    PackageCard: {
      alignment: 'left',
      hideIcons: false,
      radius: '12px',
      backgroundColor: '#ffffff',
      color: '#000000',
      shadow: 'none',
      stroke: {
        width: '1px',
        style: 'solid',
        color: '#6A6A6A',
      },
      label: {
        inside: true, // TODO: this does NOT work in the theme; I believe it may have never been properly implemented in the core
        backgroundColor: '#861B54',
        color: '#ffffff',
        radius: '28px',
      },
    },
    Price: {
      color: '#000000',
      noDecimal: false,
      dollarSign: {
        size: '20px',
      },
      cents: {
        size: '20px',
        position: 'bottom',
      },
      disclaimer: {
        position: 'bottom',
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
